@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&family=Space+Grotesk:wght@300;400;500;600;700&family=Space+Mono:wght@400;700&display=swap");
.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  text-align: center;
}

.section {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 92px;
}

.accent-text {
  color: #BFF9B4;
}

.regular-text-color {
  color: white;
}

.blurred-10 {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.blurred-5 {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  opacity: 0.8;
}

.design-frame-wrap {
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 1100px) {
  .design-frame-wrap {
    padding: 0 10px;
  }
}
.design-frame-wrap .design-frame-inner {
  top: -10px;
  left: -10px;
  height: calc(100% + 10px);
  width: calc(100% + 10px);
  padding: 100px 0;
  border: 2px solid white;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  color: white;
  position: absolute;
  text-align: center;
}
.design-frame-wrap .design-frame-inner .design-frame-title {
  font-family: "Space Mono";
  font-weight: bold;
  font-size: 62px;
  line-height: 91.82px;
  margin: unset;
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .design-frame-wrap .design-frame-inner .design-frame-title {
    font-size: 32px;
    line-height: 60px;
    text-align: center;
  }
}
@media (max-width: 550px) {
  .design-frame-wrap .design-frame-inner .design-frame-title {
    font-size: 32px;
    line-height: 45px;
    text-align: center;
  }
}
.design-frame-wrap .design-frame-inner .design-frame-text {
  font-size: 21px;
  max-width: 654px;
  text-align: center;
  padding: 0 10px;
}
@media (max-width: 768px) {
  .design-frame-wrap .design-frame-inner .design-frame-text {
    font-size: 16px;
    max-width: unset;
  }
}
.design-frame-wrap .design-frame-inner .design-frame-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 18px;
  height: 68px;
  background: radial-gradient(106.33% 2774.58% at 20.54% 118.38%, #ACE6FF 0%, #BFF9B4 100%);
  border: unset;
  font-family: "Space Mono";
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.04em;
  margin-top: 48px;
}
.design-frame-wrap .design-frame-inner .design-frame-button .button-text {
  color: #27252A;
}
@media (max-width: 768px) {
  .design-frame-wrap .design-frame-inner .design-frame-button {
    font-size: 16px !important;
    margin-top: 32px;
    line-height: 23.8px;
    gap: 8px;
    padding: 12px 24px;
  }
}
.design-frame-wrap .design-frame-outer {
  margin-left: 20px;
  position: relative;
  min-height: 560px;
  width: 100%;
  max-width: 1100px;
  border-bottom: 10px solid white;
  border-right: 10px solid white;
}
@media (max-width: 768px) {
  .design-frame-wrap .design-frame-outer {
    min-height: 327px;
  }
}

.colored-chip {
  padding: 5px 16px;
  border-radius: 100px;
}
.colored-chip .chip-text {
  font-weight: 500;
  color: #0B0B0B;
  font-size: 18px;
}
@media (max-width: 768px) {
  .colored-chip .chip-text {
    font-size: 16px;
  }
}

.property-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.property-box .property-box-title {
  font-size: 32px;
  font-weight: 700;
  color: white;
  margin-top: 31px;
  margin-bottom: 12px;
}
.property-box .property-box-content-text {
  max-width: 409px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.97px;
}
@media (max-width: 830px) {
  .property-box .property-box-content-text {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .property-box .property-box-title {
    font-size: 21px;
  }
}

.header-section {
  width: 100%;
  padding: 41px 61px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: absolute;
  z-index: 10;
}
@media (max-width: 1000px) {
  .header-section .menu-wrap ul li {
    font-size: 18px !important;
  }
}
@media (max-width: 1000px) {
  .header-section {
    padding: 21px 31px;
  }
}
.header-section .menu-responsive {
  display: none;
}
@media (max-width: 860px) {
  .header-section {
    position: fixed;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    transition: 1s;
  }
  .header-section .hamburger-icon-wrap {
    color: white;
  }
  .header-section .menu-responsive {
    display: block;
    position: relative;
    z-index: 100;
    transition: 0.2s;
  }
  .header-section .menu-wrap {
    display: none;
  }
}
.header-section .logo-wrap .logo {
  height: 100%;
}
.header-section .menu-wrap {
  color: white;
}
.header-section .menu-wrap a {
  text-decoration: none;
  font-weight: 500;
}
.header-section .menu-wrap a.accent-text {
  font-weight: 700;
}
.header-section .menu-wrap ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 26.8px;
  list-style-type: none;
  color: white;
}
.header-section .menu-wrap ul li {
  display: flex;
  align-items: center;
  font-size: 21px;
}
.header-section .menu-wrap ul li:first-of-type {
  list-style-type: none;
}
.header-section .menu-wrap ul li:hover {
  color: gray;
}
.header-section .menu-wrap ul li::after {
  display: inline-block;
  content: "";
  margin: 0 15px;
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
}
.header-section .menu-wrap ul li:last-child::after {
  display: none;
}

@media (max-width: 550px) {
  .hero-section {
    max-height: 100vh;
  }
}
.hero-section .hero-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 200px);
  width: 100%;
  padding-top: 192px;
}
@media (max-width: 860px) {
  .hero-section .hero-content {
    padding-top: 140px;
  }
}
.hero-section .hero-content .design-frame-inner .design-frame-title {
  margin-bottom: 21px;
}
.hero-section .hero-content .design-frame-inner .design-frame-button {
  margin-top: 36px;
}
@media (max-width: 768px) {
  .hero-section .hero-content .design-frame-inner .design-frame-button {
    margin-top: 24px;
  }
}
@media (max-width: 768px) {
  .hero-section .hero-content .design-frame-inner {
    font-size: 16px;
    line-height: 21px;
  }
}
.hero-section .hero-content .design-frame-outer {
  height: 496px;
  width: 100%;
  max-width: 873px;
}
@media (max-width: 768px) {
  .hero-section .hero-content .design-frame-outer {
    height: 366px;
  }
}

.traditional-vs-zk-section {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  color: white;
  width: 100%;
  flex-wrap: wrap;
}
.traditional-vs-zk-section .tzv-section-item {
  flex-basis: 50%;
  flex-grow: 0;
}
.traditional-vs-zk-section .tzv-section-item.left {
  background-color: #0B0B0B;
}
.traditional-vs-zk-section .tzv-section-item.right {
  background-color: #000000;
}
.traditional-vs-zk-section .tzv-section-item:nth-of-type(1) {
  order: 1;
}
.traditional-vs-zk-section .tzv-section-item:nth-of-type(2) {
  order: 2;
}
.traditional-vs-zk-section .tzv-section-item:nth-of-type(3) {
  order: 3;
}
.traditional-vs-zk-section .tzv-section-item:nth-of-type(4) {
  order: 4;
}
.traditional-vs-zk-section .tzv-section-item:nth-of-type(5) {
  order: 5;
}
.traditional-vs-zk-section .tzv-section-item:nth-of-type(6) {
  order: 6;
}
@media (max-width: 1100px) {
  .traditional-vs-zk-section .tzv-section-item {
    flex-basis: 100%;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(1) {
    order: 1;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(3) {
    order: 2;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(5) {
    order: 3;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(2) {
    order: 4;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(4) {
    order: 5;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(6) {
    order: 6;
    margin-top: 50px;
  }
}
@media (max-width: 550px) {
  .traditional-vs-zk-section .tzv-section-item {
    flex-basis: 100%;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(1) {
    order: 1;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(3) {
    order: 2;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(5) {
    order: 3;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(2) {
    order: 4;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(4) {
    order: 5;
  }
  .traditional-vs-zk-section .tzv-section-item:nth-of-type(6) {
    order: 6;
    margin-top: 50px;
  }
  .traditional-vs-zk-section .tzv-section-item .traditional-image-wrap, .traditional-vs-zk-section .tzv-section-item .zk-image-wrap {
    padding: 0 10px;
    width: 350px;
  }
  .traditional-vs-zk-section .tzv-section-item .traditional-image-wrap img, .traditional-vs-zk-section .tzv-section-item .zk-image-wrap img {
    width: 100%;
  }
}
.traditional-vs-zk-section .traditional-vs-zk-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 102px;
  width: 50%;
}
.traditional-vs-zk-section .traditional-vs-zk-title-box .traditional-vs-zk-title {
  font-weight: bold;
  font-size: 32px;
  text-align: center;
}
@media (max-width: 768px) {
  .traditional-vs-zk-section .traditional-vs-zk-title-box .traditional-vs-zk-title {
    font-size: 21px;
  }
}
.traditional-vs-zk-section .tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}
.traditional-vs-zk-section .tooltip .top {
  min-width: 250px;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 10px;
  color: #444444;
  background-color: #EEEEEE;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 10;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
  text-align: center;
  font-size: 14px;
}
.traditional-vs-zk-section .tooltip:hover .top {
  display: block;
}
.traditional-vs-zk-section .tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}
.traditional-vs-zk-section .tooltip .top i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #EEEEEE;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
.traditional-vs-zk-section .traditional-vs-zk-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.traditional-vs-zk-section .traditional-vs-zk-image.left {
  background-color: #0B0B0B;
}
.traditional-vs-zk-section .traditional-vs-zk-image.right {
  background-color: #000000;
}
.traditional-vs-zk-section .traditional-user-wrap {
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  max-width: 56px;
  margin-bottom: 73px;
  z-index: 1;
}
.traditional-vs-zk-section .traditional-user-wrap .question-marks {
  font-size: 18px;
  font-weight: bold;
}
.traditional-vs-zk-section .traditional-user-wrap .capitalize {
  font-size: 14px;
  text-align: center;
  line-height: 17.86px;
}
.traditional-vs-zk-section .zk-user-wrap {
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  max-width: 56px;
}
.traditional-vs-zk-section .zk-user-wrap .capitalize {
  font-size: 14px;
  text-align: center;
  line-height: 17.86px;
}
.traditional-vs-zk-section .user-animation-wrap {
  position: relative;
}
.traditional-vs-zk-section .user-animation-wrap .user-icon-wrap {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 22px;
  right: 22px;
}
.traditional-vs-zk-section .user-animation-wrap .user-icon-wrap img {
  width: 100%;
}
@media (max-width: 550px) {
  .traditional-vs-zk-section .user-animation-wrap .user-icon-wrap {
    width: 50px;
    height: 50px;
    top: 25px;
    right: 25px;
  }
}
.traditional-vs-zk-section .user-animation-wrap .user-icon-wrap.tooltip {
  width: 56px;
  height: 56px;
  background-color: #F9E2B4;
  border-radius: 50%;
}
@media (max-width: 550px) {
  .traditional-vs-zk-section .user-animation-wrap .user-icon-wrap.tooltip {
    width: 50px;
    height: 50px;
  }
}
.traditional-vs-zk-section .user-animation-wrap .user-icon-wrap .outlineVisible {
  display: none;
}
.traditional-vs-zk-section .user-animation-wrap .user-icon-wrap:hover {
  transition: 0.2s;
}
.traditional-vs-zk-section .user-animation-wrap .user-icon-wrap:hover .imageVisible {
  display: none;
}
.traditional-vs-zk-section .user-animation-wrap .user-icon-wrap:hover .outlineVisible {
  display: block;
  position: absolute;
}
.traditional-vs-zk-section .traditional-vs-zk-text-box-wrap {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 102px;
}
.traditional-vs-zk-section .traditional-vs-zk-text-box-wrap.left {
  background-color: #0B0B0B;
}
.traditional-vs-zk-section .traditional-vs-zk-text-box-wrap.right {
  background-color: #000000;
}
.traditional-vs-zk-section .traditional-vs-zk-text-box-wrap .traditional-vs-zk-text-box {
  text-align: start;
  font-size: 21px;
  line-height: 26.8px;
  font-weight: 400;
  max-width: 482px;
  height: 100%;
}
.traditional-vs-zk-section .traditional-vs-zk-text-box-wrap .traditional-vs-zk-text-box ul {
  padding: 0 20px;
  margin: unset;
}
@media (max-width: 768px) {
  .traditional-vs-zk-section .traditional-vs-zk-text-box-wrap .traditional-vs-zk-text-box {
    font-size: 16px;
    padding: 0 19px;
  }
  .traditional-vs-zk-section .traditional-vs-zk-text-box-wrap .traditional-vs-zk-text-box ul {
    padding: 0 0 0 10px;
  }
}

.use-cases-section {
  color: white;
  padding: 130px 60px;
  background-color: #0B0B0B;
}
@media (max-width: 650px) {
  .use-cases-section {
    padding: 130px 30px;
  }
}
@media (max-width: 768px) {
  .use-cases-section {
    padding: 110px 20px;
  }
  .use-cases-section .section-title {
    font-size: 21px;
  }
}
.use-cases-section .section-title {
  text-align: center;
}
.use-cases-section .use-cases-section-cases {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.use-cases-section .use-cases-section-cases .use-cases-description {
  text-align: center;
  max-width: 540px;
  font-size: 21px;
  line-height: 26.8px;
  margin-bottom: 48px;
}
@media (max-width: 768px) {
  .use-cases-section .use-cases-section-cases .use-cases-description {
    font-size: 16px;
  }
}
.use-cases-section .use-cases-section-cases .chips-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;
}
@media (max-width: 650px) {
  .use-cases-section .use-cases-section-cases .chips-wrap {
    flex-wrap: wrap;
  }
}
.use-cases-section .use-cases-section-properties {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 48px;
  padding-top: 150px;
}
@media (max-width: 830px) {
  .use-cases-section .use-cases-section-properties {
    flex-wrap: wrap;
  }
}

.library-section .design-frame-title {
  text-transform: none;
}
.library-section .library-section-text {
  line-height: 40.83px;
  font-size: 32px;
  max-width: 650px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .library-section .library-section-text {
    font-size: 16px;
    line-height: 21px;
  }
}
.library-section .library-section-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
}
@media (max-width: 768px) {
  .library-section .library-section-content {
    font-size: 21px;
    line-height: 21px;
  }
}

.demo-section {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  color: #FFFFFF;
}
.demo-section .demo-section-title {
  font-family: "Space Mono";
  font-size: 62px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .demo-section .demo-section-title {
    font-size: 32px;
  }
}

.contact-us-section .contact-us-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
}
.contact-us-section .contact-us-content .contact-us-section-text {
  font-size: 32px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .contact-us-section .contact-us-content .contact-us-section-text {
    font-size: 16px;
  }
}

.footer-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 140px;
  background-color: #0B0B0B;
  color: #FFFFFF;
  text-align: center;
}
.footer-section .footer-text {
  font-size: 21px;
}
@media (max-width: 768px) {
  .footer-section .footer-text {
    font-size: 16px;
  }
}

.page-section {
  width: 100%;
}

.section-title {
  font-size: 62px;
  font-weight: bold;
  margin: unset;
}

* {
  box-sizing: border-box;
  font-family: "Space Grotesk", sans-serif;
  outline: none;
}
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(189, 189, 189, 0.3);
  background-color: rgb(153, 153, 153);
  cursor: pointer;
}

html, body {
  margin: 0;
  padding: 0;
  background-color: #000000;
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 860px) {
  .App .responsive-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: transparent;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
  }
  .App .menu-responsive-bar {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    right: 0px;
    background-color: #000000;
    width: 279px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
  }
  .App .menu-responsive-bar .close-responsive-menu-icon-wrap {
    padding: 30px 26px 0 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
  }
  .App .menu-responsive-bar a {
    text-decoration: none;
    font-weight: 500;
  }
  .App .menu-responsive-bar a.accent-text {
    font-weight: 700;
  }
  .App .menu-responsive-bar .menu-responsive-items-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    padding-bottom: 70px;
  }
  .App .menu-responsive-bar ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 32px;
    padding-left: 20px;
    line-height: 26.8px;
    list-style-type: none;
    color: white;
  }
  .App .menu-responsive-bar ul li {
    display: flex;
    align-items: center;
    font-size: 21px;
  }
}

.content-wrap {
  max-width: 1400px;
  width: 100%;
}

a {
  text-decoration: underline;
  color: white;
}

.full-screen-section {
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media (max-height: 600px) {
  .full-screen-section {
    min-height: 100vh;
    height: 900px !important;
  }
}
.full-screen-section .full-screen-section-video-box {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  min-height: 100vh;
  width: 100%;
  position: absolute;
}/*# sourceMappingURL=App.css.map */