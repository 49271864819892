.demo-section {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $black-background;
    color: $white;

    .demo-section-title {
        font-family: 'Space Mono';
        font-size: $section-size;
        font-weight: bold;
    }
    @media(max-width: 768px) {
        .demo-section-title {
            font-size: $subtitle-size;
        }
    }
}