.colored-chip {
    padding: 5px 16px;
    border-radius: 100px;

    .chip-text {
        font-weight: 500;
        color: $gray-background;
        font-size: $smaller-size;
        @media(max-width: 768px) {
            font-size: $responsive-size;
        }
    }
}