.header-section {
    width: 100%;
    padding: 41px 61px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    position: absolute;
    z-index: 10;
    @media(max-width: 1000px) {
        .menu-wrap {
            ul {
                li {
                    font-size: $smaller-size!important;
                }
            }
           
        }
    }
    @media(max-width: 1000px) { 
        padding: 21px 31px;
    }
    .menu-responsive { 
        display:none;
    }
    @media(max-width: 860px) { 
        position: fixed;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0.9) 0%, rgba(255,255,255,0) 100%);
        backdrop-filter: blur(5px);
        transition:1s;
   
        .hamburger-icon-wrap {
            color: white;
         
        }
        .menu-responsive {
            display: block;
            position: relative;
            z-index:100;
            transition:0.2s;
           
          
        }
        .menu-wrap {
            display: none;
        }
    }
    
    .logo-wrap {
        .logo {
             height: 100%;
        }
    }
    .menu-wrap {
        color: white;
        a {
            text-decoration: none;
            font-weight: 500;
            &.accent-text {
                font-weight: 700;
            }
        }
        ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            // justify-content: space-between;
            // gap: 25px;  

            line-height: 26.8px;
            list-style-type: none;
            color: white;

            li {
                display: flex;
                align-items: center;
                font-size: $regular-size;

                &:first-of-type{
                    list-style-type: none;
                }

                &:hover {
                    color: gray;
                }

                &::after {
                    display: inline-block;
                    content: '';
                    margin: 0 15px;
                    width: 6px;
                    height: 6px;
                    background-color: white;
                    border-radius: 50%;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}