.traditional-vs-zk-section {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    color: white;
    width:100%;
    flex-wrap: wrap;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // color: white;
    // width:100%;
    // .traditional-vs-zk-title-wrap {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     justify-content: space-between;
    //     color: white;
    //     width:100%;
    .tzv-section-item {
        // width: 50%;
        flex-basis: 50%;
        flex-grow: 0;
        // flex:1;
        &.left {
            background-color: $gray-background;
        }
        &.right {
            background-color: $black-background;
        }
        &:nth-of-type(1) { 
            // title left 
            order: 1;
        }
        &:nth-of-type(2) { 
             // title right 
            order: 2; 
        }
        &:nth-of-type(3) { 
             // picture left 
            order: 3; 
        }
        &:nth-of-type(4) { 
             // picture right 
            order: 4; 
        }
        &:nth-of-type(5) { 
             // text left 
            order: 5; 
        }
        &:nth-of-type(6) { 
             // text right 
            order: 6; 
        }
        @media(max-width:1100px) {
            flex-basis: 100%;
            &:nth-of-type(1) { order: 1; }
            &:nth-of-type(3) { order: 2; }
            &:nth-of-type(5) { 
                order: 3; 
        
            }
            &:nth-of-type(2) { order: 4; }
            &:nth-of-type(4) { order: 5; }
            &:nth-of-type(6) { order: 6; margin-top: 50px; }
        }
        @media(max-width:550px) {
            flex-basis: 100%;
            &:nth-of-type(1) { order: 1; }
            &:nth-of-type(3) { order: 2; }
            &:nth-of-type(5) { 
                order: 3; 
               
            }
            &:nth-of-type(2) { order: 4; }
            &:nth-of-type(4) { order: 5; }
            &:nth-of-type(6) { order: 6; margin-top: 50px; }
            .traditional-image-wrap, .zk-image-wrap {
                    padding: 0 10px;
                    width: 350px;
                    img {
                        width: 100%;
                    }
                
            }
        }
    }
        .traditional-vs-zk-title-box {
            // flex:1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-top: 102px;
            width:50%;

            .traditional-vs-zk-title {
                font-weight: bold;
                font-size: $subtitle-size;
                text-align: center;
               
                @media(max-width:768px) {
                    font-size: $regular-size;
                }
            }
        }

    // }
    // .traditional-vs-zk-image-wrap {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     justify-content: space-between;
    //     color: white;
    //     width:100%;
        .tooltip {
            display:inline-block;
            position:relative;
            // border-bottom:1px dotted #666;
            text-align:left;
        }
        
        .tooltip .top {
            min-width:250px; 
            top:-20px;
            left:50%;
            transform:translate(-50%, -100%);
            padding:10px 10px;
            color:#444444;
            background-color:#EEEEEE;
            font-weight:normal;
            font-size:13px;
            border-radius:8px;
            position:absolute;
            z-index:10;
            box-sizing:border-box;
            box-shadow:0 1px 8px rgba(0,0,0,0.5);
            display:none;
            text-align: center;
            font-size: $small-size;
        }
        
        .tooltip:hover .top {
            display:block;
        }
        
        .tooltip .top i {
            position:absolute;
            top:100%;
            left:50%;
            margin-left:-12px;
            width:24px;
            height:12px;
            overflow:hidden;
        }
        
        .tooltip .top i::after {
            content:'';
            position:absolute;
            width:12px;
            height:12px;
            left:50%;
            transform:translate(-50%,-50%) rotate(45deg);
            background-color:#EEEEEE;
            box-shadow:0 1px 8px rgba(0,0,0,0.5);
        }
        .traditional-vs-zk-image {
            // flex:1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap:4px;

            &.left {
                background-color: $gray-background;
            }
            &.right {
                background-color: $black-background;
            }

        }
        .traditional-user-wrap {
            margin-top: 38px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2px;
            max-width: 56px;
            margin-bottom: 73px;
            z-index: 1;
        
            .question-marks {
                font-size: $smaller-size;
                font-weight: bold;
            }
            .capitalize {
                font-size: $small-size;
                text-align: center;
                line-height: 17.86px;
            }
        }
        .zk-user-wrap {
            margin-top: 31px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 7px;
            max-width: 56px;
            
            .capitalize {
                font-size: $small-size;
                text-align: center;
                line-height: 17.86px;
            }
            
        }
        .user-animation-wrap {
            position:relative;
           .user-icon-wrap {
                width: 56px;
                height: 56px;
                position: absolute;
                top: 22px;
                right: 22px;
                img {
                    width:100%;
                }
                @media(max-width:550px) {
                    width: 50px;
                    height: 50px;
                    top: 25px;
                    right: 25px;
                 }
                &.tooltip {
                    width: 56px;
                    height: 56px;
                    background-color: #F9E2B4;
                    border-radius: 50%;
                    @media(max-width:550px) {
                        width: 50px;
                        height: 50px; 

                    }
                }
                .outlineVisible {
                    display: none;

                }
                &:hover {
                    transition: 0.2s;
                    .imageVisible {
                        display: none;
                    }
                    .outlineVisible {
                        display: block;
                        position: absolute;
                        
                    }
                }
           }
        }
    // }
    // .traditional-vs-zk-text-wrap {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: flex-start;
    //     justify-content:space-between;
    //     // background-color: pink;
    //     width: 100%;
        
        // background-color: $gray-background;
        .traditional-vs-zk-text-box-wrap {
            color: white;
           
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            // flex:1;
            padding-bottom:102px;
            &.left {
                background-color: $gray-background;
            }
            &.right {
                background-color: $black-background;
            }

            .traditional-vs-zk-text-box {
                text-align:start;
                font-size: $regular-size;
                line-height: 26.8px;
                font-weight: 400;
                max-width: 482px;
                height: 100%;
                ul {
                    padding: 0 20px;
                    margin: unset;
                }
                @media(max-width:768px) {
                    font-size: $responsive-size;
                    padding: 0 19px;
                    ul {
                        padding: 0 0 0 10px ;
                    }
                }
            }
        }
        
    // }
}