
// Text
.bold {
	font-weight: bold; //700
}

.semi-bold {
    font-weight: 600;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.subtitle {
    font-style: normal;
    font-weight: 700;
    font-size: $subtitle-size;
    line-height: 41px;
    text-align: center;
}

.section {
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: 700;
    font-size: $section-size;
    line-height: 92px;
}


// Colors 

.accent-text {
    color: $accent-text;
}

.regular-text-color {
    color: white;
}

//Effects

.blurred-10 { 
    backdrop-filter: blur(10px);
}

.blurred-5 {
    backdrop-filter: blur(5px);
}

// Behavior
.clickable {
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}
