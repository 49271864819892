.contact-us-section {

    .contact-us-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        width: 100%;
        .contact-us-section-text {
            font-size: $subtitle-size;
            font-weight: 400;
            @media(max-width:768px) {
                font-size: $responsive-size;
            }
         
        }
    }
}