.property-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .property-box-title {
        font-size: $subtitle-size;
        font-weight: 700;
        color: white;
        margin-top: 31px;
        margin-bottom:12px;
    }

    .property-box-content-text {
        max-width: 409px;
        font-size: $responsive-size;
        font-weight: 400;
        line-height: 22.97px;
        @media(max-width:830px) {
            max-width: 100%;
        }
    }
    @media(max-width:768px) {
        .property-box-title {
            font-size: $regular-size;
            
        }

    }
}