
// Background colors
$black-background: #000000;
$gray-background: #0B0B0B;
$lavander-background: '#D4CBF9';
$lime-background: "#BFF9B4";
$purple-background: '#9C96F5';
$sky-background: "#ACE6FF";
$button-color: #27252A;

$radial-gradient: radial-gradient(circle, rgba(172,230,255,1) 0%, rgba(191,249,180,1) 100%);
// background: rgb(172,230,255);


// Text colors
$accent-text: #BFF9B4;
$white: #FFFFFF;

// Text sizes 
$small-size: 14px;
$responsive-size: 16px;
$smaller-size: 18px;
$regular-size: 21px;
$accent-size: 24px;
$subtitle-size: 32px;
$section-size: 62px;



