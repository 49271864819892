// Fonts
// @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&family=Space+Grotesk:wght@300;400;500;600;700&family=Space+Mono:wght@400;700&display=swap');
// Variables
@import './styles/variables';

// Defaults
@import './styles/defaults';

// Shared components
@import './shared-components/shared-components';

// Sections
@import './sections/sections';



*  {
	box-sizing: border-box;
	font-family: 'Space Grotesk', sans-serif;
	outline: none;

	//scrolling

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb
	{
	  border-radius: 10px;
	  -webkit-box-shadow: inset 0 0 6px rgba(189, 189, 189, 0.3);
	  background-color: rgb(153, 153, 153);
      cursor: pointer;
	}
}


html, body {
	margin: 0;
	padding: 0;
	// overflow: hidden;
	background-color: $black-background;
	scroll-behavior: smooth;

}
.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	@media(max-width: 860px) {  
		.responsive-blur {
			position:fixed;
			top:0;
			left:0;
			width: 100vw;
			height: 100vh;
			z-index: 100;
			background-color: transparent;
			backdrop-filter: blur(15px);
		}
		.menu-responsive-bar {
			position: fixed;
			z-index: 1000;
			top: 0;
			bottom: 0;
			right: 0px;
			background-color: #000000;
			width: 279px;
			min-height: 100vh;
			// border-top: 5px solid  #BFF9B4;
			
			
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 10px;

			.close-responsive-menu-icon-wrap {
			
				padding:30px 26px 0 0 ;
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				justify-content: flex-end;
				width:100%;
			}
			a {
				text-decoration: none;
				font-weight: 500;
				&.accent-text {
					font-weight: 700;
				}
			}
			.menu-responsive-items-wrap {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex:1;
				width: 100%; 
				padding-bottom: 70px;
			}
			ul {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex:1;
				gap: 32px; 
				padding-left:20px;
				line-height: 26.8px;
				list-style-type: none;
				color: white;
				li {
					display: flex;
					align-items: center;
					font-size: $regular-size;
				}
			}
			
		}
	}
}

.content-wrap {
	max-width: 1400px;
	width: 100%;
	// margin: auto;
}

a {
	text-decoration: underline;
	color: white;
}


.full-screen-section {
    // width: 100vw;
    min-height:100vh;
	height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
	@media(max-height:600px) {
		min-height:100vh;
		height: 900px!important;
	}
    .full-screen-section-video-box {
        // width: 100vw;
		// height: 100%;
		// flex:1;
		height: 100%;
		object-fit: cover;
		min-height: 100vh;
		width: 100%;
		position:absolute;
		
    }
	
    
    
}