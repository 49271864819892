.hero-section {
    @media(max-width:550px) {
        max-height: 100vh;
    }
    .hero-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: calc(100vh - 200px);
        width: 100%;
        padding-top: 192px;
        @media(max-width: 860px) { 
            padding-top: 140px;
        }
       
        .design-frame-inner {
            .design-frame-title {
                margin-bottom: 21px;
            }
            .design-frame-button {
                margin-top: 36px;
                @media(max-width:768px) { 
                    
                    margin-top: 24px;
                    
                }
            }
            @media(max-width:768px) {
                font-size: $responsive-size;
                line-height: 21px;
               
            }
        }
        .design-frame-outer {
            height: 496px;
            width:100%;
            max-width: 873px;

            @media(max-width:768px) {
                height: 366px;
            }
        }
    }
    
    
}