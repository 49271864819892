.use-cases-section {
    color: white;
    padding: 130px 60px;
    background-color: $gray-background;
    @media(max-width: 650px) {
        padding: 130px 30px;
    }
    @media(max-width: 768px) {
        padding: 110px 20px;
        .section-title {
            font-size: $regular-size;
        }

    }
    .section-title {
        text-align: center;
    }
    .use-cases-section-cases {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // gap: 10px;
        .use-cases-description {
            text-align: center;
            max-width: 540px;
            font-size: $regular-size;
            line-height: 26.8px;
            margin-bottom: 48px;
            @media(max-width: 768px) {
                font-size: $responsive-size;
            }
        }
        .chips-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap:14px;
            @media(max-width:650px)  {
                flex-wrap:wrap;
            }
           
        }
    }

    .use-cases-section-properties {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        gap: 48px;
        padding-top: 150px;
        @media(max-width: 830px) {
            flex-wrap: wrap;
        }
    }

}