.design-frame-wrap {
    width: 100%;
    margin: 0 auto;
    z-index:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position:relative;
    @media(max-width: 1100px) {
        padding: 0 10px;
    }
    .design-frame-inner {
        top: -10px;
        left: -10px;
        height: calc(100% + 10px);
        // min-height: 560px;
        width:calc(100% + 10px);
        // max-width: 1100px;
        padding: 100px 0;
        border: 2px solid white;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // gap: 30px;
        backdrop-filter: blur(5px);
        color: white;
        position: absolute;
        text-align: center;
        
        .design-frame-title {
            font-family: "Space Mono";
            font-weight: bold;
            font-size: 62px;
            line-height: 91.82px;
            margin: unset;
            margin-bottom: 32px;
            @media(max-width:768px) {
                font-size: $subtitle-size;
                line-height: 60px;
                text-align: center;
            }
            @media(max-width:550px) {
                font-size: $subtitle-size;
                line-height: 45px;
                text-align: center;
            }
        }

        .design-frame-text {
            font-size: $regular-size;
            max-width: 654px;
            text-align: center;
            padding: 0 10px;
            @media(max-width:768px) {
                font-size: $responsive-size;
                max-width: unset;
             }
            
        }
        
        .design-frame-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 32px;
            gap: 18px;
            height: 68px;
            background: radial-gradient(106.33% 2774.58% at 20.54% 118.38%, #ACE6FF 0%, #BFF9B4 100%);
            border: unset;
            font-family: 'Space Mono';
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.04em;
            margin-top:48px;
            // color: $sky-background;
            .button-text {
                color: #27252A;

            }
            @media(max-width:768px) { 
                font-size: $responsive-size!important;
                margin-top: 32px;
                line-height: 23.8px;
                gap:8px;
                padding: 12px 24px;
            }
            // @media(max-width:550px) {
            //     margin-top: 24px;
            //     line-height: 18px;
            //     gap:8px;
            //     padding: 12px 24px;
            //     font-size: $responsive-size!important;
            //  }
            
        }

    }
    .design-frame-outer {
        margin-left: 20px;
        position:relative;
        min-height: 560px;
        width:100%;
        max-width: 1100px;
        border-bottom: 10px solid white;
        border-right: 10px solid white;
        // margin-top: -20px;
        // margin-left: 20px;
        @media(max-width:768px) {
            min-height: 327px;
        }

        
    }
    
}