// Sections
@import './header-section/header-section';

@import './hero-section/hero-section';

@import './traditional-vs-zk-section/traditional-vs-zk-section';

@import './use-cases-section/use-cases-section';

@import './library-section/library-section';

@import './demo-section/demo-section';

@import './contact-us-section/contact-us-section';

@import './footer-section/footer-section';


.page-section {
    width:100%;
    // min-height: 100vh;
}

.section-title {
    font-size: $section-size;
    font-weight: bold;
    margin:unset;

}
