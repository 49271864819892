.footer-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 140px;
    background-color: $gray-background;
    color: $white;
    text-align: center;
    
    .footer-text {
        font-size: $regular-size;
        @media(max-width: 768px) {
            font-size: $responsive-size;
        }
    }
}