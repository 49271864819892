.library-section {
    // max-height: 100vh;
    .design-frame-title {
        text-transform: none;
    }
    .library-section-text {
        line-height: 40.83px;
        font-size: $subtitle-size;
        max-width: 650px;
        font-weight: 400;
        @media(max-width:768px) {
            font-size: $responsive-size;
            line-height: 21px;
        }
        
    }
    .library-section-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        width: 100%;
        @media(max-width:768px) {
            font-size: $regular-size;
            line-height: 21px;
        }
    }
    // .design-frame-button  {

    // }
}